import React, { useState } from 'react';
import MenuItems from "../../Data/MenuItems";
import "./mobilemenu.scss";
import "../../../style/style.scss";
import Logo from "../../../assets/images/logo.jpg";

const Header = () => {
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});
  const [petaMenuOpen, setPetaMenuOpen] = useState({});

  const toggleMobileMenu = () => {
    setMobileMenuActive(!isMobileMenuActive);
  };

  const closeMobileMenu = () => {
    setMobileMenuActive(false);
  };

  const toggleSubMenu = (index) => {
    setSubMenuOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const togglePetaMenu = (index, subIndex) => {
    setPetaMenuOpen(prevState => ({
      ...prevState,
      [`${index}-${subIndex}`]: !prevState[`${index}-${subIndex}`]
    }));
  };

  const renderPetaMenu = (petaMenu, index, subIndex) => (
    <ul className="list-unstyled peta-menu" style={{ display: petaMenuOpen[`${index}-${subIndex}`] ? 'block' : 'none' }}>
      {petaMenu.map((petaMenuItem, petaIndex) => (
        <li key={petaIndex}>
          <a href={petaMenuItem.link}>{petaMenuItem.title}</a>
        </li>
      ))}
    </ul>
  );

  const renderSubMenu = (subMenu, index) => (
    <ul className="list-unstyled sub-menu" style={{ display: subMenuOpen[index] ? 'block' : 'none' }}>
      {subMenu.map((subMenuItem, subIndex) => (
        <li key={subIndex} className="submenu-item">
          <a href={subMenuItem.link} onClick={() => subMenuItem.petaMenu && togglePetaMenu(index, subIndex)}>
            {subMenuItem.title}
            {subMenuItem.petaMenu && <i className={`fas fa-chevron-right float-end ${petaMenuOpen[`${index}-${subIndex}`] ? 'open' : ''}`}></i>}
          </a>
          {subMenuItem.petaMenu && renderPetaMenu(subMenuItem.petaMenu, index, subIndex)}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <div className="mobile-menu d-block d-xl-none">
        <div className="mobile-topbar">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="logo d-flex align-items-center">
              <a href="/">
              <img src={Logo} alt="logo" />
            </a>
              <div className="logo-text">
              Chicago Telugu Association
              </div>
              </div>
              <div className="bars" onClick={toggleMobileMenu}>
                <i className="fas fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
        <div className={`mobile-menu-overlay ${isMobileMenuActive ? 'active' : ''}`} onClick={toggleMobileMenu}></div>
        <div className={`mobile-menu-main ${isMobileMenuActive ? 'active' : ''}`}>
          <div className="logo">
            <a href="/" className="d-flex align-items-center">
              <img src={Logo} alt="logo" />
              <div className="logo-text">
              Chicago Telugu Association
              </div>
            </a>
          <div className="close-mobile-menu" onClick={closeMobileMenu}><i className="fas fa-times"></i></div>
          </div>
          <div className="mobile-menu-body">
            <div className="menu-list">
              <ul className="list-unstyled">
                {MenuItems.map((menuItem, index) => (
                  <li key={index} className="sub-mobile-menu">
                      <a
                        href={menuItem.link || '#'}
                        onClick={(e) => {
                          if (menuItem.subMenu) {
                            e.preventDefault(); 
                            toggleSubMenu(index);
                          }
                        }}
                      >
                        {menuItem.title}
                        {menuItem.subMenu && (
                          <i className={`fas fa-chevron-down float-end ${subMenuOpen[index] ? 'open' : ''}`}></i>
                        )}
                      </a>
                      {menuItem.subMenu && renderSubMenu(menuItem.subMenu, index)}
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <ul className="contact-list">
            <li>
            <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
                        <path d="M8 3C8.5 3 10.5 7.5 10.5 8C10.5 9 9 10 8.5 11C8 12 9 13 10 14C10.3943 14.3943 12 16 13 15.5C14 15 15 13.5 16 13.5C16.5 13.5 21 15.5 21 16C21 18 19.5 19.5 18 20C16.5 20.5 15.5 20.5 13.5 20C11.5 19.5 10 19 7.5 16.5C5 14 4.5 12.5 4 10.5C3.5 8.5 3.5 7.5 4 6C4.5 4.5 6 3 8 3Z">
                          <animate fill="freeze" dur="0.6s" values="64;0" />
                          <animateTransform attributeName="transform" begin="0.6s;lineMdPhoneCallLoop0.begin+2.6s" dur="0.5s" type="rotate" values="0 12 12;15 12 12;0 12 12;-12 12 12;0 12 12;12 12 12;0 12 12;-15 12 12;0 12 12" />
                        </path>
                        <path d="M14 7.04404C14.6608 7.34734 15.2571 7.76718 15.7624 8.27723M16.956 10C16.6606 9.35636 16.2546 8.77401 15.7624 8.27723" opacity="0">
                          <set id="lineMdPhoneCallLoop0" attributeName="opacity" begin="0.7s;lineMdPhoneCallLoop0.begin+2.7s" to="1" />
                          <animate fill="freeze" begin="0.7s;lineMdPhoneCallLoop0.begin+2.7s" dur="0.2s" values="4;8" />
                          <animate fill="freeze" begin="1.3s;lineMdPhoneCallLoop0.begin+3.3s" dur="0.3s" values="0;4" />
                          <set attributeName="opacity" begin="1.6s;lineMdPhoneCallLoop0.begin+3.6s" to="0" />
                        </path>
                        <path d="M20.748 9C20.3874 7.59926 19.6571 6.347 18.6672 5.3535M15 3.25203C16.4105 3.61507 17.6704 4.3531 18.6672 5.3535" opacity="0">
                          <set attributeName="opacity" begin="1s;lineMdPhoneCallLoop0.begin+3s" to="1" />
                          <animate fill="freeze" begin="1s;lineMdPhoneCallLoop0.begin+3s" dur="0.2s" values="10;20" />
                          <animate fill="freeze" begin="1.5s;lineMdPhoneCallLoop0.begin+3.5s" dur="0.3s" values="0;10" />
                          <set attributeName="opacity" begin="1.8s;lineMdPhoneCallLoop0.begin+3.8s" to="0" />
                        </path>
                      </g>
                    </svg>
                  </div>
                  <div className="info">
              <span className="title">Click To Call</span>
              <div className="text"><a href="tel:+911-630-409-6800">1-630-409-6800</a></div>
                  </div>
            </li>
          </ul>
          <div className="social-icon">
            <ul className="list-unstyled">
              <li><a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
              <li><a href="https://www.youtube.com/"><i className="fab fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
