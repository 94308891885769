import brand1 from "../../assets/images/brand-1.png";
import brand2 from "../../assets/images/brand-2.png";
import brand3 from "../../assets/images/brand-3.png";
import brand4 from "../../assets/images/brand-4.png";
import brand5 from "../../assets/images/brand-5.png";

const BrandData = [
    {
        image: brand1
    },
    {
        image: brand2
    },
    {
        image: brand3
    },
    {
        image: brand4
    },
    {
        image: brand5
    }
];

export default BrandData;