import React, { useRef } from "react";
import { useParams } from 'react-router-dom';
import EventDetailData from "../../Data/EventDetailData";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import "./event-detail.scss";
import ReactToPrint from 'react-to-print';
function EventDetail() {
    let { id } = useParams();
    const eventDetail = EventDetailData.find(item => item.id === parseInt(id));
    const componentRef = useRef();
    return (
        <>
            <Breadcrumb />
            <div className="event-detail mb-50" ref={componentRef}>
                <div className="container">
                    <div className="row">
                        <div className="event-desc-left col-lg-12 col-md-12 col-sm-12">
                            <div className="event-desc-img">
                                <img src={eventDetail.image} alt="product image" className="img-fluid" />
                            </div>
                            <div className="event-desc-detail">
                                <h2>{eventDetail.title}</h2>
                                {eventDetail.content.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                            <div className="event-srevice">
                                <div className="event-service-main-title">
                                    <ul className="list-checked">
                                        {eventDetail.serviceList.map((serviceList, index) => (
                                            <li key={index}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height={20} width={20} viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z" />
                                                </svg>{serviceList}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="specification">
                                <div className="section-title">
                                    <h3>{eventDetail.specification}</h3>
                                </div>
                                <table className="rantal-table rantal-table-striped col-lg-6 col-md-12 col-sm-12">
                                    <tbody>
                                        {eventDetail.specificationData.map((specificationData, index) => (
                                            <tr key={index}>
                                                <td>{specificationData.label}</td>
                                                <td>{specificationData.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3 col-md-6 col-md-12 col-sm-12">
                                <ReactToPrint
                                    trigger={() => (
                                        <button className="event-btn">
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path strokeDasharray="14" strokeDashoffset="14" d="M6 19h12"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.4s" values="14;0" /></path><path strokeDasharray="18" strokeDashoffset="18" d="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="18;0" /><animate attributeName="d" calcMode="linear" dur="1.5s" keyTimes="0;0.7;1" repeatCount="indefinite" values="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5;M12 4 h2 v3 h2.5 L12 11.5M12 4 h-2 v3 h-2.5 L12 11.5;M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5" /></path></g></svg>
                                            </span>
                                            <span>Download flyer</span>
                                        </button>
                                    )}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EventDetail;
