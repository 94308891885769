import React from "react";
import SliderData from "../Data/SliderData";
import Slider from 'react-slick';
import "./sliderbenner.scss";
function SliderBenner() {
    const settings = {
        dots: true,
        arrow: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            }
        ],
    };
    return (
        <div className="main-slider mb-50">
            <Slider {...settings}>
                {SliderData.map((data, index) => (
                    <div key={index} className="align-items-center position-relative">
                        <div className="slider-img">
                            <img src={data.image} alt="sliderimage" className="img-fluid" />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
export default SliderBenner;
