import CultureImage from "../../assets/images/culture-image.png";

const KnowTeluguCultureData = [
    {
        image: CultureImage,
        title: "ప్రియమయిన తెలుగు సోదర సోదరీమణులారా,",
        Description: [
            "మన తెలుగు భాష మహోన్నతమైనది. మన సంస్కృతి సాంప్రదాయాలు, అభిరుచులు ఈ భూమి మీద ప్రత్యేకమైనవి. తెలుగువారి ఆత్మగౌరవం, సహన శీలత, శ్రద్ధాశక్తులు అపారమైనవి. ఈ సందర్భంగా మన భాషా సంస్కృతి గొప్పతనాన్ని ఆవిష్కరించే కొన్ని మాటలు చెప్పుకుందాం",
            "మన పూర్వీకులు తెలుగు భాషను ఎంతో ఆదరించేవారు. వారి మాటలు, రచనలు, కవిత్వాలు మనకు ఆనందాన్ని కలిగించేవి.",
            "ఆదికవి నన్నయ, తిక్కన, ఎల్లప్రగడ, సహజ కవి భక్త పోతనామత్యులు మరియు ఆధునిక కాలంలో శ్రీ కందుకూరి వీరేశలింగం పంతులు గారు, శ్రీ గురజాడ అప్పారావు గారు వంటి మహనీయులు తమ రచనల ద్వారా మన తెలుగు భాషకు అమూల్యమైన సేవ చేశారు.",
            "ఇక మన భారతదేశ స్వాతంత్ర్య సంగ్రామంలో అల్లూరి సీతారామరాజు గారు చూపిన త్యాగం మనకు స్ఫూర్తిదాయకం. వారి వీరత్వం, ధైర్యం, దేశభక్తి మనందరికీ ఆదర్శంగా నిలుస్తాయి.",
            "తెలుగు భాషను రక్షించుకోవటం, అభివృద్ధి చేయటం మన అందరి బాధ్యత. ఈ భావనలతో మన యువతరాన్ని ప్రోత్సహించాలి. పిల్లలకు మన పాత కథలు, పాఠాలు చెప్పి, వారి హృదయాల్లో తెలుగు భాషపై ప్రేమను రేకెత్తించాలి.",
            "మరియు, మన సినిమారంగం కూడా మన సంస్కృతిని ప్రపంచానికి పరిచయం చేసింది. నందమూరి తారక రామారావు గారు, ఎస్వీ రంగారావు గారు వంటి గొప్ప నటులు మన తెలుగు తెరకు చిరస్మరణీయమైన కీర్తి తెచ్చారు.",
            "తెలుగు సంస్కృతి, తన సాంప్రదాయ సంపద మరియు మేధావిత్వానికి ప్రసిద్ధి చెందినది, భారతదేశ ఆర్థిక వ్యవస్థను రూపాంతరం చేసిన ఆర్థిక సంస్కరణలను అమలు చేయడంలో కీలక పాత్ర పోషించిన దూరదృష్టి కలిగిన నాయకుడు పి.వి. నరసింహారావు వంటి మహానుభావులను పుట్టించింది.",
            "కృష్ణ, గోదావరి, తుంగభద్ర, పెన్న ఎన్నొన్ని జీవనదులు మనకున్నాయి, ఆ జీవనదుల ఆశ్విరాద ఫలం మరియు తెలుగు ప్రజల స్వేదంతో తడిసి ముద్దైన మన తెలుగు నేల మొత్తం భారతదేశానికే అన్నపూర్ణగా వెలిసింది. అటువంటి మధురమైన సంస్కృతికి వారసులం మనం.",
            "మన ప్రభువులు, మన పూర్వీకులు మనకు ఇచ్చిన ఈ మాతృభూమి, ఈ భాష మనకు అతి విలువైనవి. వాటిని మనం గౌరవంగా, కృతజ్ఞతగా భావించాలి.",
            "మీరందరు మన తెలుగు సంస్కృతిని, భాషను ప్రేమిస్తారు, పూజిస్తారు అనే నమ్మకంతో....",
        ],
        regard: "మీ మిత్రులు",
        name: "చికాగో తెలుగు సంఘం",
    }
];

export default KnowTeluguCultureData;