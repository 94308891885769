import React from 'react';
import PresidentMessageData from "../Data/PresidentMessageData";
import "./president-message.scss";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
function PresidentMessage() {
    return (
        <>
            <Breadcrumb />
            <div className="message-president mb-50">
                <div className="message-section-wrapper">
                    <div className="container">
                        <div className="row align-items-center col-lg-10 col-12 m-auto">
                            {PresidentMessageData.map((data, index) => (
                                <React.Fragment key={index}>
                                    <div className="col-lg-6 wow fadeInLeft">
                                        <div className="message-image">
                                            <img src={data.image} alt="message-img" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 message-contain-section wow fadeInRight">
                                        <div className="title-section">
                                            <div className="main-title">
                                                <h2>{data.Title}</h2>
                                            </div>
                                            <div className="role">
                                                <span>{data.role}</span>
                                            </div>
                                        </div>
                                        <div className="message-desc">
                                            {data.Description.map((item, idx) => (
                                                <p key={idx}>{item}</p>
                                            ))}
                                        </div>
                                        <div className="sign-image">
                                            <img src={data.sign} alt="sign-img" className="img-fluid" />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PresidentMessage;
