import React from 'react';
// import WOW from 'wowjs';
import SliderBenner from "../../component/SliderBenner/SliderBenner";
import Testimonial from "../../component/Testimonial/Testimonial";
import Aboutus from "../Aboutus/Aboutus";
import Counter from "../Counter/Counter";

const Home = () => {
    // useEffect(() => {
    //     new WOW.WOW().init();
    // }, []);
    return (
        <React.Fragment>
            <SliderBenner />
            <Aboutus />
            <Counter />
            <Testimonial />
        </React.Fragment>
    );
}

export default Home;