import React from "react";
import { Routes, Route } from "react-router-dom";
import HeaderTopBar from "./Header/HeaderTopBar/HeaderTopBar";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import BottomToTop from "./BottomToTop/BottomToTop";
import Home from "./Home/Home";
import Aboutus from "./Aboutus/Aboutus";
import History from "./History/History";
import OurTeam from "./OurTeam/OurTeam";
import ChairmanMessage from "./ChairmanMessage/ChairmanMessage";
import ContactUs from "./ContactUs/ContactUs";
import OurCauses from "./OurCauses/OurCauses";
import BrandList from "./BrandList/BrandList";
import Events from "./Events/Events";
import PresidentMessage from "./PresidentMessage/PresidentMessage";
import OurImpact from "./OurImpact/OurImpact";
import GetInvolved from "./GetInvolved/GetInvolved";
import Gallery from "./Gallery/Gallery";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import RentalDetail from "./Events/EventDetail/index";
import KnowTeluguCulture from "./KnowTeluguCulture/KnowTeluguCulture";

function Layout() {
  // const path = window.location.pathname;
  // const locations = [
  //   "/"
  // ];
  
  return (
    <React.Fragment>
      <HeaderTopBar />
      <Header />
      <ScrollToTop />
        <Routes>
        <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<Aboutus />} />
          <Route exact path="/History" element={<History />} />
          <Route exact path="/our-team" element={<OurTeam />} />
          <Route exact path="/Chairman-Message" element={<ChairmanMessage />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/our-causes" element={<OurCauses />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/current" element={<Events />} />
          <Route path="/event-detail/:id" element={<RentalDetail />} />
          <Route exact path="/president-message" element={<PresidentMessage />} />
          <Route exact path="/our-impact" element={<OurImpact />} />
          <Route exact path="/get-involved" element={<GetInvolved />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/know-telugu-culture" element={<KnowTeluguCulture />} >
          </Route>
        </Routes>
        <BrandList />
      <Footer />
      <BottomToTop />
    </React.Fragment>
  );
}

export default Layout;
