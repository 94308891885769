import team1 from "../../assets/images/team-1.jpg";
import team2 from "../../assets/images/team-2.jpg";
import team3 from "../../assets/images/team-3.jpg";
import team4 from "../../assets/images/team-4.jpg";
import team5 from "../../assets/images/team-5.jpg";
import team6 from "../../assets/images/team-6.jpg";
import team7 from "../../assets/images/team-7.jpg";
import team8 from "../../assets/images/team-8.jpg";
import team9 from "../../assets/images/team-9.jpg";
import team10 from "../../assets/images/team-10.jpg";
import team11 from "../../assets/images/team-11.jpg";
import team12 from "../../assets/images/team-12.jpg";
import team13 from "../../assets/images/team-13.jpg";
import team14 from "../../assets/images/team-14.jpg";

const OurteamData = [
    {
        name: "Ravi Achanta",
        role: "Founder and Director",
        description: "Leading the association with a vision to unite the community and promote cultural activities.",
        img: team1,
    },
    {
        name: "Praveen Moturu ",
        role: "Founder and Director",
        description: "Dedicated to organizing events and fostering a sense of community among members.",
        img: team2,
    },
    {
        name: "Nagendra Vege",
        role: "President",
        description: "Responsible for managing the financial aspects of the association.",
        img: team3,
    },
    {
        name: "Rao Achanta",
        role: "Chairman, BOD",
        description: "Organizing cultural programs and ensuring the promotion of Telugu heritage.",
        img: team4,
    },
    {
        name: "Prasad Talluru",
        role: "Former President and Director",
        description: "Engaging the youth in various activities to keep them connected to their roots.",
        img: team5,
    },
    {
        name: "Ashok Pagadala",
        role: "Director",
        description: "Planning and executing events for the association throughout the year.",
        img: team6,
    },
    {
        name: "Ramesh Maryala",
        role: "Director",
        description: "Handling media relations and promoting the association's activities.",
        img: team11,
    },
    {
        name: "Srinivas Chundu",
        role: "Former President and Director",
        description: "Managing memberships and ensuring active participation from members.",
        img: team7,
    },
    {
        name: "Phani Ramineni",
        role: "Founder and Director",
        description: "Handling media relations and promoting the association's activities.",
        img: team8,
    },
    {
        name: "Seshu Uppalapati",
        role: "Director",
        description: "Planning and executing events for the association throughout the year.",
        img: team9,
    },
    {
        name: "Rahul Viratapu",
        role: "Director",
        description: "Managing memberships and ensuring active participation from members.",
        img: team10,
    },
    {
        name: "Praveen Koyyalamudi",
        role: "Director",
        description: "Manages memberships and ensures active participation from members.",
        img: team12,
    },
    {
        name: "Saichand Mekala",
        role: "Volunteer Services and Marketing, Director",
        description: "Organizes and coordinates cultural events to preserve and celebrate Telugu heritage.",
        img: team13,
    },
    {
        name: "Nanda Kishore",
        role: "Cultural Services, Director",
        description: "Plans and executes cultural programs to promote and sustain Telugu traditions.",
        img: team14,
    },
];

export default OurteamData;
