import React from 'react';
import OurCausesData from "../Data/OurCausesData";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./our-causes.scss";
function OurCauses() {
    return (
        <>
            <Breadcrumb />
            <div className="our-causes-area mb-50">
                <div className="container">
                    <div className="row">
                        <div className="title-section">
                            <div className="sub-title">
                                <span>What We Do</span>
                            </div>
                            <div className="main-title">
                                <h2>Our Causes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {OurCausesData.map((item, index) => (
                                <div className="our-causes-timeline" key={index}>
                                    {index % 2 === 0 ? (
                                        <div className="row">
                                            <div className="col-12 col-lg-6 content-wrapper wow fadeInLeft">
                                                <div className="our-causes-left-content">
                                                    <span className="our-causes-icon"></span>
                                                    <div className="our-causes-content">
                                                        <h4>{item.number}</h4>
                                                        <h3>{item.title}</h3>
                                                        <span>{item.date}</span>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 image-wrap">
                                                <div className="right-image">
                                                    <img src={item.img} alt={item.title} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 col-lg-6 image-wrap">
                                                <div className="left-image">
                                                    <img src={item.img} alt={item.title} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 content-wrapper wow fadeInRight">
                                                <div className="our-causes-right-content">
                                                    <span className="our-causes-icon"></span>
                                                    <div className="our-causes-content">
                                                        <h4>{item.number}</h4>
                                                        <h3>{item.title}</h3>
                                                        <span>{item.date}</span>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OurCauses;