import Gallary1 from "../../assets/images/gallery-1.jpg";
import Gallary2 from "../../assets/images/gallery-2.jpg";
import Gallary3 from "../../assets/images/gallery-3.jpg";
import Gallary4 from "../../assets/images/gallery-4.jpg";
import Gallary5 from "../../assets/images/gallery-5.jpg";
import Gallary6 from "../../assets/images/gallery-6.jpg";
import Gallary7 from "../../assets/images/gallery-7.jpg";
import Gallary8 from "../../assets/images/gallery-8.jpg";
import Gallary9 from "../../assets/images/gallery-9.jpg";
import Gallary10 from "../../assets/images/gallery-10.jpg";
import Gallary11 from "../../assets/images/gallery-11.jpg";
import Gallary12 from "../../assets/images/gallery-12.jpg";
import Gallary13 from "../../assets/images/gallery-13.jpg";
import Gallary14 from "../../assets/images/gallery-14.jpg";
import Gallary15 from "../../assets/images/gallery-15.jpg";
import Gallary16 from "../../assets/images/gallery-16.jpg";
import Gallary17 from "../../assets/images/gallery-17.jpg";
import Gallary18 from "../../assets/images/gallery-18.jpg";
import Gallary19 from "../../assets/images/gallery-19.jpg";
import Gallary20 from "../../assets/images/gallery-20.jpg";
import Gallary21 from "../../assets/images/gallery-21.jpg";
import Gallary22 from "../../assets/images/gallery-22.jpg";
import Gallary23 from "../../assets/images/gallery-23.jpg";
import Gallary24 from "../../assets/images/gallery-24.jpg";
import Gallary25 from "../../assets/images/gallery-25.jpg";
import Gallary26 from "../../assets/images/gallery-26.jpg";
import Gallary27 from "../../assets/images/gallery-27.jpg";
import Gallary28 from "../../assets/images/gallery-28.jpg";
import Gallary29 from "../../assets/images/gallery-29.jpg";
import Gallary30 from "../../assets/images/gallery-30.jpg";
import Gallary31 from "../../assets/images/gallery-31.jpg";
import Gallary32 from "../../assets/images/gallery-32.jpg";
import Gallary33 from "../../assets/images/gallery-33.jpg";
import Gallary34 from "../../assets/images/gallery-34.jpg";
import Gallary35 from "../../assets/images/gallery-35.jpg";
import Gallary36 from "../../assets/images/gallery-36.jpg";
import Gallary37 from "../../assets/images/gallery-37.jpg";
import Gallary38 from "../../assets/images/gallery-38.jpg";
import Gallary39 from "../../assets/images/gallery-39.jpg";
import Gallary40 from "../../assets/images/gallery-40.jpg";
import Gallary41 from "../../assets/images/gallery-41.jpg";
import Gallary42 from "../../assets/images/gallery-42.jpg";
import Gallary43 from "../../assets/images/gallery-43.jpg";
import Gallary44 from "../../assets/images/gallery-44.jpg";
import Gallary45 from "../../assets/images/gallery-45.jpg";
import Gallary46 from "../../assets/images/gallery-46.jpg";
import Gallary47 from "../../assets/images/gallery-47.jpg";
import Gallary48 from "../../assets/images/gallery-48.jpg";
import Gallary49 from "../../assets/images/gallery-49.jpg";
import Gallary50 from "../../assets/images/gallery-50.jpg";
import Gallary51 from "../../assets/images/gallery-51.jpg";
import Gallary52 from "../../assets/images/gallery-52.jpg";
import Gallary53 from "../../assets/images/gallery-53.jpg";
import Gallary54 from "../../assets/images/gallery-54.jpg";
import Gallary55 from "../../assets/images/gallery-55.jpg";
import Gallary56 from "../../assets/images/gallery-56.jpg";
import Gallary57 from "../../assets/images/gallery-57.jpg";
import Gallary58 from "../../assets/images/gallery-58.jpg";
import Gallary59 from "../../assets/images/gallery-59.jpg";
import Gallary60 from "../../assets/images/gallery-60.jpg";
import Gallary61 from "../../assets/images/gallery-61.jpg";
import Gallary62 from "../../assets/images/gallery-62.jpg";
import Gallary63 from "../../assets/images/gallery-63.jpg";
import Gallary64 from "../../assets/images/gallery-64.jpg";
import Gallary65 from "../../assets/images/gallery-65.jpg";

const galleryData = [
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary1,
    fullSize: Gallary1,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary2,
    fullSize: Gallary2,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary3,
    fullSize: Gallary3,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary4,
    fullSize: Gallary4,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary5,
    fullSize: Gallary5,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary6,
    fullSize: Gallary6,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary7,
    fullSize: Gallary7,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary8,
    fullSize: Gallary8,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary9,
    fullSize: Gallary9,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary10,
    fullSize: Gallary10,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary11,
    fullSize: Gallary11,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary12,
    fullSize: Gallary12,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary13,
    fullSize: Gallary13,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary14,
    fullSize: Gallary14,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary15,
    fullSize: Gallary15,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary16,
    fullSize: Gallary16,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary17,
    fullSize: Gallary17,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary18,
    fullSize: Gallary18,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary19,
    fullSize: Gallary19,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary20,
    fullSize: Gallary20,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary21,
    fullSize: Gallary21,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary22,
    fullSize: Gallary22,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary23,
    fullSize: Gallary23,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary24,
    fullSize: Gallary24,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary25,
    fullSize: Gallary25,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary26,
    fullSize: Gallary26,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary27,
    fullSize: Gallary27,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary28,
    fullSize: Gallary28,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary29,
    fullSize: Gallary29,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary30,
    fullSize: Gallary30,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary31,
    fullSize: Gallary31,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary32,
    fullSize: Gallary32,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary33,
    fullSize: Gallary33,
  },
  {
    name: "Feed My Starving Children Event at North Central College, Naperville, IL ",
    category: ['all', '2019'],
    date: '02/17/2019',
    image: Gallary34,
    fullSize: Gallary34,
  },
  {
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary35,
    fullSize: Gallary35,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary36,
    fullSize: Gallary36,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary37,
    fullSize: Gallary37,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary38,
    fullSize: Gallary38,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary39,
    fullSize: Gallary39,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary40,
    fullSize: Gallary40,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary41,
    fullSize: Gallary41,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary42,
    fullSize: Gallary42,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary43,
    fullSize: Gallary43,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary44,
    fullSize: Gallary44,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary45,
    fullSize: Gallary45,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary46,
    fullSize: Gallary46,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary47,
    fullSize: Gallary47,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary48,
    fullSize: Gallary48,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary49,
    fullSize: Gallary49,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary50,
    fullSize: Gallary50,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary51,
    fullSize: Gallary51,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary52,
    fullSize: Gallary52,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary53,
    fullSize: Gallary53,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary54,
    fullSize: Gallary54,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary55,
    fullSize: Gallary55,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary56,
    fullSize: Gallary56,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary57,
    fullSize: Gallary57,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary58,
    fullSize: Gallary58,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary59,
    fullSize: Gallary59,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary60,
    fullSize: Gallary60,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary61,
    fullSize: Gallary61,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary62,
    fullSize: Gallary62,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary63,
    fullSize: Gallary63,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary64,
    fullSize: Gallary64,
},
{
    name: "Ugadi and Sri Ramanavami Celebrations (2019)",
    category: ['all', '2019'],
    date: '04/14/2019',
    image: Gallary65,
    fullSize: Gallary65,
},

];

export default galleryData;
