import EventImage1 from "../../assets/images/event-1.jpg";
import EventImage2 from "../../assets/images/event-2.jpg";
import EventImage3 from "../../assets/images/event-3.jpg";
import EventImage4 from "../../assets/images/event-4.jpg";
import EventImage5 from "../../assets/images/event-5.jpg";

const EventDetailData = [
  {
    id: 1,
    image: EventImage1,
    title: "Cultural Festival",
    content: [
      "The Chicago Telugu Association is excited to host its annual Cultural Festival, celebrating the rich heritage and traditions of Telugu culture. This vibrant event will feature traditional dance performances, music, and a variety of food stalls offering authentic Telugu cuisine.",
      "Join us for a day filled with cultural activities, community bonding, and a showcase of our rich heritage. The festival is open to all and promises to be a memorable experience for families and individuals alike.",
      "Throughout the festival, attendees can enjoy performances by local artists and musicians, showcasing traditional Telugu dance forms and musical genres. There will be interactive booths where participants can learn more about Telugu traditions and history.",
      "A key highlight of the festival will be our food stalls, featuring a diverse selection of traditional Telugu dishes. From spicy curries to sweet delicacies, there will be something for everyone to enjoy.",
      "The festival also includes workshops on Telugu crafts and cooking, offering attendees a hands-on experience with traditional art forms and culinary techniques.",
      "For children, there will be a dedicated area with games and activities designed to engage and entertain them, ensuring that the festival is a fun experience for the whole family.",
      "We encourage everyone to come dressed in traditional attire to add to the festive atmosphere and show support for our vibrant culture.",
      "The Cultural Festival is not only a celebration of our heritage but also an opportunity to strengthen community ties and foster a deeper appreciation for Telugu culture."
    ],
    serviceList: [
      "Traditional dance and music performances",
      "Authentic Telugu food stalls",
      "Cultural workshops and activities",
      "Family-friendly atmosphere",
      "Interactive booths and exhibits",
      "Children's games and activities",
      "Traditional attire encouraged"
    ],
    specification: "Event Details",
    specificationData: [
      { label: "Date", value: "August 15, 2024" },
      { label: "Time", value: "10:00 AM - 6:00 PM" },
      { label: "Location", value: "Aurora, IL" },
      { label: "Contact", value: "1-630-409-6800" }
    ]
  },
  {
    id: 2,
    image: EventImage2,
    title: "Language Workshop",
    content: [
      "Our Language Workshop aims to promote the Telugu language through interactive sessions and learning activities. This workshop is designed for both beginners and advanced learners, providing a comprehensive understanding of Telugu grammar, vocabulary, and conversation skills.",
      "Led by experienced language instructors, the workshop will offer practical exercises, cultural insights, and a supportive environment to enhance your Telugu language skills.",
      "Participants will benefit from personalized instruction tailored to their proficiency levels, ensuring effective learning and progress.",
      "The workshop will cover essential topics such as sentence structure, common phrases, and idiomatic expressions used in everyday conversations.",
      "In addition to language lessons, attendees will have the opportunity to engage in group discussions and practice their skills in real-life scenarios.",
      "Cultural context will also be an integral part of the workshop, helping learners understand the nuances of Telugu traditions and social interactions.",
      "Materials and resources will be provided to support learning, including handouts and online access to supplementary content.",
      "By the end of the workshop, participants will have gained valuable skills and confidence in using the Telugu language in various settings."
    ],
    serviceList: [
      "Interactive language sessions",
      "Experienced instructors",
      "Cultural insights and exercises",
      "Supportive learning environment",
      "Personalized instruction",
      "Group discussions and practice",
      "Learning materials provided"
    ],
    specification: "Workshop Details",
    specificationData: [
      { label: "Date", value: "September 10, 2024" },
      { label: "Time", value: "6:00 PM - 8:00 PM" },
      { label: "Location", value: "Aurora, IL" },
      { label: "Instructor", value: "Experienced Telugu language experts" },
      { label: "Contact", value: "1-630-409-6800" }
    ]
  },
  {
    id: 3,
    image: EventImage3,
    title: "Charity Fundraiser",
    content: [
      "Join us for a Charity Fundraiser event organized by the Chicago Telugu Association to support local and international causes. This event will feature a gala dinner, live auction, and performances by renowned artists.",
      "Your participation will contribute to meaningful projects and help make a difference in our community and beyond. We look forward to your support and participation in this noble cause.",
      "The evening will begin with a cocktail reception, where guests can mingle and network while enjoying hors d'oeuvres and beverages.",
      "The gala dinner will feature a multi-course meal prepared by top chefs, showcasing a blend of international and local cuisine.",
      "During the live auction, attendees will have the chance to bid on exclusive items and experiences, with all proceeds going to our supported causes.",
      "Entertainment will be provided by renowned artists, including live music and performances that promise to add a festive touch to the evening.",
      "A highlight of the night will be the awards ceremony, recognizing individuals and organizations for their outstanding contributions to various causes.",
      "The Charity Fundraiser is not only an opportunity to give back but also a chance to enjoy an elegant and memorable evening with fellow community members."
    ],
    serviceList: [
      "Gala dinner with multi-course meal",
      "Live auction with exclusive items",
      "Entertainment by renowned artists",
      "Cocktail reception",
      "Awards ceremony",
      "Networking opportunities",
      "Support for local and international causes"
    ],
    specification: "Fundraiser Details",
    specificationData: [
      { label: "Date", value: "October 5, 2024" },
      { label: "Time", value: "7:00 PM - 11:00 PM" },
      { label: "Location", value: "Aurora, IL" },
      { label: "Auction Items", value: "Exclusive and valuable items" },
      { label: "Contact", value: "1-630-409-6800" }
    ]
  },
  {
    id: 4,
    image: EventImage4,
    title: "Health and Wellness Fair",
    content: [
      "The Chicago Telugu Association is hosting a Health and Wellness Fair aimed at promoting healthy lifestyles within our community. The fair will feature health screenings, wellness workshops, fitness demonstrations, and more.",
      "Come and take advantage of the various resources available to improve your well-being and learn from experts in the field of health and wellness.",
      "Health screenings will include checks for blood pressure, cholesterol levels, and glucose, providing valuable insights into your health status.",
      "Wellness workshops will cover topics such as stress management, nutrition, and exercise, offering practical tips and strategies for a healthier lifestyle.",
      "Fitness demonstrations will showcase different exercise routines, from yoga to strength training, to help you find activities that suit your fitness goals.",
      "Expert consultations will be available for personalized advice and guidance on health-related issues.",
      "Additionally, there will be booths with information on local health services and products that support wellness and healthy living.",
      "The fair is designed to be an inclusive event, welcoming individuals of all ages and fitness levels to participate and benefit from the resources provided."
    ],
    serviceList: [
      "Health screenings",
      "Wellness workshops",
      "Fitness demonstrations",
      "Expert consultations",
      "Information booths on health services and products",
      "Inclusive for all ages and fitness levels",
      "Free entry"
    ],
    specification: "Fair Details",
    specificationData: [
      { label: "Date", value: "November 12, 2024" },
      { label: "Time", value: "11:00 AM - 4:00 PM" },
      { label: "Location", value: "Aurora, IL" },
      { label: "Activities", value: "Health screenings, workshops, fitness demos" },
      { label: "Contact", value: "1-630-409-6800" }
    ]
  },
  {
    id: 5,
    image: EventImage5,
    title: "Annual Gala Dinner",
    content: [
      "Our Annual Gala Dinner is a highlight of the year for the Chicago Telugu Association, bringing together members and supporters for an elegant evening of dining and entertainment. This prestigious event will feature a keynote address, awards ceremony, and live music.",
      "Celebrate with us and enjoy an evening of sophistication while supporting our organization's ongoing initiatives.",
      "The event will start with a reception where guests can enjoy cocktails and hors d'oeuvres while mingling with other attendees.",
      "A gourmet dinner will follow, featuring a selection of fine dining options and desserts crafted by renowned chefs.",
      "During the evening, a keynote speaker will deliver an inspiring address, sharing insights and updates on our organization's achievements and future goals.",
      "The awards ceremony will recognize outstanding contributions from individuals and organizations who have made a significant impact.",
      "Entertainment will include live music performances, adding a touch of elegance and enjoyment to the evening.",
      "The Annual Gala Dinner is a perfect opportunity to network, celebrate our successes, and contribute to the growth and success of our community."
    ],
    serviceList: [
      "Gourmet dinner",
      "Reception with cocktails and hors d'oeuvres",
      "Keynote address",
      "Awards ceremony",
      "Live music performances",
      "Networking opportunities",
      "Elegant and memorable experience"
    ],
    specification: "Gala Dinner Details",
    specificationData: [
      { label: "Date", value: "December 20, 2024" },
      { label: "Time", value: "6:00 PM - 10:00 PM" },
      { label: "Location", value: "Aurora, IL" },
      { label: "Entertainment", value: "Live music" },
      { label: "Contact", value: "1-630-409-6800" }
    ]
  }
];

export default EventDetailData;
