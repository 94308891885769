import React from 'react';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import backgroundImage from '../../assets/images/join-us-image.jpg';
import "./get-involved.scss";
const GetInvolved = () => {
  return (
    <>
      <Breadcrumb />
      <div className="get-involved-section-wrapper mb-50" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="get-involved-area">
          <div className="container">
            <div className="row align-items-top mb-50">
              <div className="title-section">
                <div className="sub-title">
                  <span>Become a Part of Our Community</span>
                </div>
                <div className="main-title">
                  <h2>Join Us</h2>
                </div>
              </div>
              <div className="col-lg-7 involved-section-wrapper involved-form">
                <form name="contact-form">
                  <div className="row involved-form-group">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">First Name</label>
                        <input type="text" className="form-control" name="name" placeholder="First Name" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">Last Name</label>
                        <input type="text" className="form-control" name="name" placeholder="Last Name" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">Email Address</label>
                        <input type="email" className="form-control" name="email" placeholder="Email Address" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">Phone Number</label>
                        <input type="number" className="form-control" name="monumber" placeholder="Phone Number" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">Address 1</label>
                        <textarea className="form-control involved-message" name="message" id="message"
                          cols="30" rows="5" placeholder="Address 1"></textarea></div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">Address 2</label>
                        <textarea className="form-control involved-message" name="message" id="message"
                          cols="30" rows="5" placeholder="Address 2"></textarea></div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label className="required">City</label>
                        <input type="text" className="form-control" name="name" placeholder="City" />
                      </div>
                    </div>
                    <div className="col-sm-3 col-12">
                      <div className="form-group">
                        <label className="required">Zip Code</label>
                        <input type="number" className="form-control" name="zipcode" placeholder="Zip Code" />
                      </div>
                    </div>
                    <div className="col-sm-3 col-12">
                      <div className="form-group">
                        <label className="required">country</label>
                        <select>
                          <option>--Select a country--</option>
                          <option>United States</option>
                          <option>Canada</option>
                          <option>United Kingdom</option>
                          <option>India</option>
                          <option>Australia</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <div className="form-group">
                        <label className="required title-label">Interested in</label>
                        <div className="checkbox-items">
                        <label>
                            <input type="checkbox" id="Charity" />
                            Charity
                          </label>
                          <label>
                            <input type="checkbox" id="Culture" />
                            Culture
                          </label>
                          <label>
                            <input type="checkbox" id="Education" />
                            Education
                          </label>
                          <label>
                            <input type="checkbox" id="Sports" />
                            Sports
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-md-12 btn-wrapper">
                      <button type="submit" value="Submit" id="submit" className="default-theme-btn">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetInvolved;