import President from "../../assets/images/president.jpg";
import Signature from "../../assets/images/signature-2.png";

const PresidentMessageData = [
    {
        image: President,
        sign: Signature,
        Title: "Nagendra Vege",
        role: "President",
        Description: [
            "As the President of the Board of Directors, I am privileged to lead the Chicago Telugu Association in our pursuit of cultural preservation and community empowerment. Our goal is to create a welcoming environment where every member feels connected and valued.",
            "Our association serves as a hub for cultural exchange, providing opportunities for learning and celebration. We strive to promote Telugu language and traditions through various educational programs and cultural events.",
            "The milestones we have achieved, from successful cultural festivals to comprehensive outreach initiatives, are a testament to our members' dedication and passion. These achievements inspire us to continue working towards a unified and vibrant community.",
            "I invite all members to engage actively with our initiatives, share their ideas, and contribute to our growth. Your participation is vital to our mission, as it enriches our community and strengthens our bonds.",
            "Together, we can build a community that honors our heritage while embracing the future. The Chicago Telugu Association stands as a beacon of cultural pride and community support, and I am confident that, with your help, we will continue to thrive.",
        ],
    }
];

export default PresidentMessageData;
