import React from "react";
export const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="slider-arrow right-btn"
      onClick={onClick}
    >
      <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.84984 7C1.29756 6.99979 0.849668 7.44733 0.849457 7.99962C0.849245 8.5519 1.29679 8.99979 1.84907 9L1.84984 7ZM30.7068 8.71811C31.0975 8.32774 31.0977 7.69457 30.7074 7.3039L24.3458 0.93751C23.9555 0.546837 23.3223 0.546595 22.9316 0.936971C22.541 1.32735 22.5407 1.96051 22.9311 2.35118L28.5858 8.0102L22.9268 13.6649C22.5361 14.0553 22.5358 14.6884 22.9262 15.0791C23.3166 15.4698 23.9498 15.47 24.3404 15.0796L30.7068 8.71811ZM1.84907 9L29.9996 9.01073L30.0004 7.01073L1.84984 7L1.84907 9Z" fill="black"></path></svg>
    </div>
  );
};
export const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slider-arrow left-btn" onClick={onClick}>
      <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.1501 7C29.7024 6.99979 30.1503 7.44733 30.1505 7.99962C30.1507 8.5519 29.7032 8.99979 29.1509 9L29.1501 7ZM0.29314 8.71811C-0.0975332 8.32774 -0.0977758 7.69457 0.292599 7.3039L6.65412 0.93751C7.0445 0.546837 7.67766 0.546595 8.06834 0.936971C8.45901 1.32735 8.45925 1.96051 8.06888 2.35118L2.41419 8.0102L8.07321 13.6649C8.46388 14.0553 8.46413 14.6884 8.07375 15.0791C7.68338 15.4698 7.05021 15.47 6.65954 15.0796L0.29314 8.71811ZM29.1509 9L1.00036 9.01073L0.999594 7.01073L29.1501 7L29.1509 9Z" fill="black"></path></svg>
    </div>
  );
};
