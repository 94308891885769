const MenuItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'About Us',
    link: '/about-us',
    subMenu: [
      {
        title: 'History',
        link: '/history',
      },
      {
        title: 'Chairman Message',
        link: '/chairman-message',
      },
      {
        title: 'President Message',
        link: '/president-message',
      },
      {
        title: 'Our Team',
        link: '/our-team',
      },
    ],
  },
  {
    title: 'Our Causes',
    link: '/our-causes',
    subMenu: [
      {
        title: 'Our Impact',
        link: '/our-impact',
      },
    ],
  },
  {
    title: 'Events',
    link: '/events',
    subMenu: [
      {
        title: 'Current Events',
        link: '/current',
      },
      {
        title: 'Past Events',
        link: '/past',
      },
    ],
  },
  {
    title: 'Know Telugu Culture',
    link: '/know-telugu-culture',
  },
  {
    title: 'Gallery',
    link: '/gallery',
  },
  {
    title: 'Get Involved',
    link: '/get-involved',
  }
];

export default MenuItems;
