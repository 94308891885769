import React from 'react';
import './know-telugu-culture.scss';
import KnowTeluguCultureData from '../Data/KnowTeluguCultureData';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
const KnowTeluguCulture = () => {
    return (
        <>
            <Breadcrumb />
            <div className="know-sec mb-50">
                <div className="container">
                    {KnowTeluguCultureData.map((data, index) => (
                        <React.Fragment key={index}>
                            <div className="image">
                                <img src={data.image} alt={data.Title} className="img-fluid" />
                            </div>
                            <h2 className="know-title">{data.title}</h2>
                            <div className="know-description">
                                {data.Description.map((desc, idx) => (
                                    <li key={idx}>{desc}</li>
                                ))}
                            </div>
                            <div className="regard">
                                <span>{data.regard}</span>
                                <div className="regard-name">{data.name}</div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
};
export default KnowTeluguCulture;
