import Chairman from "../../assets/images/chairman.jpg";
import Signature from "../../assets/images/signature.png";

const ChairmanMessageData = [
    {
        image: Chairman,
        sign: Signature,
        Title: "Rao Achanta",
        role: "Chairman, BOD",
        Description: [
            "As the Chairman of the Board of Directors, I am honored to lead the Chicago Telugu Association in its mission to connect and support our Telugu-speaking community in Chicago.",
            "Our association is dedicated to fostering cultural unity and providing a platform for meaningful community engagement. Together, we aim to preserve our rich heritage and promote the values that bind us.",
            "We have achieved remarkable milestones over the years, from organizing cultural festivals to expanding our community outreach programs. These accomplishments reflect our collective efforts and commitment.",
            "I encourage everyone to actively participate in our events, share their ideas, and contribute to the growth of our association. Your involvement is crucial to our continued success.",
            "Let us work together to build a stronger, more vibrant community for all. Our shared dedication will ensure that the Chicago Telugu Association remains a beacon of culture and support for years to come.",
        ],
    }
];

export default ChairmanMessageData;