import React from "react";
import Slider from 'react-slick';
import BrandData from "../Data/BrandData";
import { NextArrow, PrevArrow } from "../Arrows";
import "./brand-list.scss";

function BrandList() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        autoplaySpeed:1500,
        slidesToShow: 4,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToScroll: 1,
        cssEase: 'linear',
        speed: 8000,
        responsive: [
            {
                breakpoint: 991,
                settings: { slidesToShow: 3, }
            },
            {
                breakpoint: 650,
                settings: { slidesToShow: 2, }
            },
            {
                breakpoint: 320,
                settings: { slidesToShow: 2, }
            }
        ]
    };

    return (
        <>
            <div className="brand-section-wrapper mb-50 wow fadeInUp">
                <div className="brand-section">
                    <div className="container">
                        <div className="title-section">
                            <div className="sub-title">
                                <span>Our Esteemed Sponsors</span>
                            </div>
                            <div className="main-title">
                                <h2>Community Support</h2>
                            </div>
                        </div>
                        <div className="brand-box-wrapper">
                            <div className="brand-list-slider">
                                <Slider {...settings}>
                                    {BrandData.map((data, index) => (
                                        <div key={index} className="brand-main-item">
                                            <div className="brand-item">
                                                <div className="brand-box">
                                                    <div className="brand-icon">
                                                        <img src={data.image} alt={data.name} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BrandList;
