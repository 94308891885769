import Sliderimage from "../../assets/images/slider-image.jpg";
import Sliderimage2 from "../../assets/images/slider-image-2.jpg";

const SliderData = [
    {
        image: Sliderimage,
    },
    {
        image: Sliderimage2,
    }
];

export default SliderData;