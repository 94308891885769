import React from "react";
import CountUp from "react-countup";
import "./counter.scss";
function Counter() {
    return (
        <section className="counter-area mb-50 wow fadeInUp">
            <div className="container">
                <div className="title-section">
                    <div className="sub-title">
                        <span>Our Impact in Numbers</span>
                    </div>
                    <div className="main-title">
                        <h2>Celebrating Our Achievements</h2>
                    </div>
                </div>
                <div className="counter-wrap">
                    <div className="counter-item">
                        <div className="content">
                            <h3>
                                <span className="counter">
                                    <CountUp
                                        start={0}
                                        duration={5}
                                        end={100}
                                        delay={0}
                                    />
                                </span>+
                            </h3>
                            <p className="counter-text">Community Events</p>
                        </div>
                    </div>
                    <div className="counter-item">
                        <div className="content">
                            <h3>
                                <span className="counter">
                                    <CountUp
                                        start={0}
                                        duration={5}
                                        end={1000}
                                        delay={0}
                                    />
                                </span>+
                            </h3>
                            <p className="counter-text">Active Members</p>
                        </div>
                    </div>
                    <div className="counter-item">
                        <div className="content">
                            <h3>
                                <span className="counter">
                                    <CountUp
                                        start={0}
                                        duration={5}
                                        end={100}
                                        delay={0}
                                    />
                                </span>+
                            </h3>
                            <p className="counter-text">Cultural Programs</p>
                        </div>
                    </div>
                    <div className="counter-item">
                        <div className="content">
                            <h3>
                                <span className="counter">
                                    <CountUp
                                        start={0}
                                        duration={5}
                                        end={15}
                                        delay={0}
                                    />
                                </span>+
                            </h3>
                            <p className="counter-text">Years of Service</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Counter;
