
import React from 'react';
import { useLocation } from 'react-router-dom';
import AboutData from '../Data/AboutData';
import Descimage from "../../assets/images/gandhiji.jpg";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import "./about-us.scss";
const AboutUs = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    return (
        <>
            {!isHomePage && <Breadcrumb />}
            <div className="about-us mb-50">
                <div className="aboutus-section-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            {AboutData.map((data, index, idx) => (
                                <React.Fragment key={index}>
                                    <div className="col-lg-6 wow fadeInLeft">
                                        <div className="about-us-image">
                                            <img src={data.image} alt="about-us-img" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 about-us-contain-section wow fadeInRight">
                                        <div className="title-desc">
                                            {data.desc.map((desc, idx) => (
                                                <span key={idx}>{desc}</span>
                                            ))}
                                        </div>
                                        <div className="title-section">
                                            <div className="sub-title">
                                                <span>{data.SubTitle}</span>
                                            </div>
                                            <div className="main-title">
                                                <h2>{data.Title}</h2>
                                            </div>
                                        </div>
                                        <div className="abou-us-desc">
                                            {data.Description.map((item, idx) => (
                                                <p key={idx}>{item}</p>
                                            ))}
                                        </div>
                                        <div className="about-us-box">
                                            <div className="about-us-icon">
                                                <img src={Descimage} alt="" />
                                            </div>
                                            <div className="about-us-content">
                                                <div className="auther-content">
                                                    <h6 className="about-us-designation">"Be the change that you wish to see in the world."</h6>
                                                    <h4 className="about-us-title">- Mahatma Gandhi</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="default-theme-btn">
                                            <a href="/events">{data.BtnText}</a>
                                        </button>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AboutUs;