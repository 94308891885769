const TestimonialInfoData = [
    {
        title: "Lakshmi Rao",
        designation: "Cultural Coordinator",
        desc: "The Chicago Telugu Association has been a cornerstone for our community. Their cultural programs are well-organized and bring a sense of unity among us. I am proud to be part of this vibrant community!",
    },
    {
        title: "Suresh Kumar",
        designation: "Event Participant",
        desc: "Attending the events organized by the Chicago Telugu Association has been a wonderful experience. The Diwali celebration was spectacular, with traditional music and dance performances. The team's attention to detail made it unforgettable.",
    },
    {
        title: "Anitha Reddy",
        designation: "Parent and Volunteer",
        desc: "My children have greatly benefited from the educational workshops and cultural activities organized by the Chicago Telugu Association. It promotes our language and culture, providing a platform for the younger generation to learn and grow.",
    },
    {
        title: "Rajesh Patil",
        designation: "Community Member",
        desc: "The Chicago Telugu Association has been instrumental in connecting Telugu-speaking people in the Chicago area. Their efforts in organizing cultural, educational, and social events are commendable. The sense of community and belonging is invaluable.",
    },
    {
        title: "Meena Gupta",
        designation: "Cultural Enthusiast",
        desc: "Being a part of the Chicago Telugu Association's events has been an enriching experience. They celebrate our festivals and traditions in a heartwarming way. The association provides a great platform for all ages to connect and celebrate our heritage.",
    },
];

export default TestimonialInfoData;
