import Aboutimage from "../../assets/images/about-image.jpg";

const AboutData = [
    {
        image: Aboutimage,
        desc: [
            "తెలుగు జాతి మనది నిండుగా వెలుగు జాతి మనది.",
            "తెలుగు వాడినని గర్వించు తెలుగు భాషని భావితరానికి అందించు."
        ],
        SubTitle: "About Us",
        Title: "Connecting Telugu Communities in Chicago",
        Description: [
            "Welcome to the Chicago Telugu Association, a 501(c)(3) nonprofit organization dedicated to fostering community, culture, and service for Telugu-speaking individuals and families in the Chicagoland area and suburbs.",
            "Our association provides a platform for cultural events, social gatherings, and community support. We aim to preserve and promote Telugu culture while building a strong, connected community.",
            "Join us in celebrating our heritage and contributing to a vibrant and supportive network of Telugu-speaking residents in Chicago.",
        ],
        BtnText: "Read More"
    }
];

export default AboutData;
    