import React from "react";
import { Link } from 'react-router-dom';
import EventsData from '../Data/EventsData';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import "./events.scss";
const Events = () => {
    return (
        <>
            <Breadcrumb />
            <div className="event-section-wrapper mb-50">
                <div className="event-section">
                    <div className="container">
                        <div className="title-section">
                            <div className="sub-title">
                                <span>Our event</span>
                            </div>
                            <div className="main-title" data-aos="fade-up">
                                <h2>Discover Our Events</h2>
                            </div>
                        </div>
                        <div className="event-slider row">
                            {EventsData.map((event, index) => (
                                <div key={index} className="event-main-item col-lg-4 col-md-6 col-12">
                                    <div className="event-item">
                                        <div className="event-box">
                                            <div className="event-img">
                                                <img src={event.image} alt="image" className="img-fluid" />
                                            </div>
                                            <div className="event-content-main">
                                                <div className="date-wrap">
                                                    <div className="event-date">{event.Date}</div>
                                                </div>
                                                <div className="event-content">
                                                    <div className="event-content-top">
                                                        <div className="time">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                                <path fill="currentColor" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
                                                            </svg> {event.Time}
                                                        </div>
                                                        <div className="location">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                                                                <path fill="currentColor" d="M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3" />
                                                                <path fill="currentColor" d="m16 30l-8.436-9.949a35 35 0 0 1-.348-.451A10.9 10.9 0 0 1 5 13a11 11 0 0 1 22 0a10.9 10.9 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 1 0-18 0a8.9 8.9 0 0 0 1.813 5.395" />
                                                            </svg> {event.Location}
                                                        </div>
                                                        <h4>{event.Title}</h4>
                                                        <div className="event-contact">
                                                            {event.Contacts.map((contact, contactIndex) => (
                                                                <div key={contactIndex}>
                                                                    <div className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m0 8a3 3 0 1 1 3-3a3 3 0 0 1-3 3m9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z" /></svg>
                                                                    </div>{contact.name}, <a href="tel:+1234567890" className="contact-no">{contact.phone}</a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="contact">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M48 399.26C48 335.19 62.44 284 90.91 247c34.38-44.67 88.68-68.77 161.56-71.75V72L464 252L252.47 432V329.35c-44.25 1.19-77.66 7.58-104.27 19.84c-28.75 13.25-49.6 33.05-72.08 58.7L48 440Z" /></svg>
                                                            Submit your requests directly to our cultural team at: <a href="https://www.chicagoteluguassociation.org/">{event.teamEmail}</a></div>
                                                        <Link className="default-theme-btn" to={`/event-detail/${event.id}`}>
                                                        <span>View Flyer</span>
                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Events;
