import React from "react";
import { useLocation } from 'react-router-dom';
import "./breadcrumb.scss";
function Breadcrumb() {
  const location = useLocation();
  const getPageTitle = () => {
    switch (true) {
      case location.pathname === '/':
        return 'Home';
      case location.pathname === '/history':
        return 'History';
      case location.pathname === '/our-team':
        return 'Our Team';
      case location.pathname === '/chairman-message':
        return 'Chairman Message';
      case location.pathname === '/contact':
        return 'Contact Us';
      case location.pathname === '/our-causes':
        return 'Our Causes';
      case location.pathname === '/events':
        return 'all Events';
      case location.pathname === '/about-us':
        return 'about us';
      case location.pathname === '/president-message':
        return 'President Message';
      case location.pathname === '/our-impact':
        return 'our impact';
      case location.pathname === '/get-involved':
        return 'get involved';
      case location.pathname === '/gallery':
        return 'gallery';
        case location.pathname.startsWith('/event-detail/'):
        return 'event Detail';
        case location.pathname.startsWith('/know-telugu-culture'):
          return 'know telugu culture';
      default:
        return '';
    }
  };
  return (
    <div className="bread-crumb-bar">
      <div className="container">
        <div className="row align-items-center inner-banner">
          <div className="col-md-12 col-12 ">
            <div className="breadcrumb-list">
              <h2>{getPageTitle()}</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path fill="currentColor" d="M18.672 11H17v6c0 .445-.194 1-1 1h-4v-6H8v6H4c-.806 0-1-.555-1-1v-6H1.328c-.598 0-.47-.324-.06-.748L9.292 2.22c.195-.202.451-.302.708-.312c.257.01.513.109.708.312l8.023 8.031c.411.425.539.749-.059.749" /></svg> Home</a></li>
                  <li className="breadcrumb-item" aria-current="page">{getPageTitle()}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Breadcrumb;
