import impactImage from "../../assets/images/impact.png";

const OurImpactData = [
    {
        image: impactImage,
        title: "Impact Report: Chicago Telugu Association",
        Description: [
            "Since our inception, the Chicago Telugu Association has been dedicated to making a meaningful difference in our community. Our outreach programs have reached over 5,000 individuals, offering support and resources to those who need it most.",
            "We are committed to enhancing the lives of our community members through a variety of initiatives, including educational support, cultural enrichment, and social services. These efforts aim to uplift and empower individuals, fostering a strong and connected community.",
            "Our educational programs provide vital resources to students and families, including tutoring, scholarships, and mentorship opportunities. By investing in education, we help pave the way for future success and growth.",
            "In addition to education, our cultural programs celebrate and preserve the rich heritage of the Telugu community. Events, festivals, and workshops are designed to connect individuals with their cultural roots and promote cross-cultural understanding.",
            "We also focus on social services, offering assistance with essential needs such as food, housing, and healthcare. Our goal is to provide a safety net for those in times of crisis and to ensure that everyone has access to the support they need.",
            "Our volunteers play a crucial role in delivering these services. Their dedication and hard work make a tangible difference in the lives of many. We are proud of their commitment and the positive impact they have on our community.",
            "Through partnerships with local organizations and businesses, we amplify our reach and effectiveness. These collaborations allow us to offer a wider range of services and to address the needs of our community more comprehensively.",
            "We continuously assess and adapt our programs to ensure they meet the evolving needs of our community. Feedback from those we serve helps us refine our approach and maximize our impact.",
            "Our success is a testament to the generosity and support of our donors and sponsors. Their contributions enable us to continue our work and to expand our programs, reaching even more individuals in need.",
            "We are committed to transparency and accountability in all our endeavors. Regular reports and updates are provided to keep our community informed about our activities and the outcomes of our programs.",
            "Looking ahead, we aim to further enhance our impact by exploring new opportunities and addressing emerging needs. Our vision is to create a thriving, supportive community where everyone can achieve their full potential.",
            "The Chicago Telugu Association remains dedicated to its mission of service, culture, and unity. Through our ongoing efforts, we strive to make a lasting positive impact and to build a brighter future for all."
        ],
    }
];

export default OurImpactData;
