import culture1 from "../../assets/images/culture-1.jpg";
import culture2 from "../../assets/images/culture-2.jpg";
import culture3 from "../../assets/images/culture-3.jpg";
import culture4 from "../../assets/images/culture-4.jpg";

const OurCausesData = [
    {
        number: "01",
        title: "Cultural Celebrations",
        description: "We celebrate and preserve Telugu culture through events such as cultural festivals, traditional dance performances, and music concerts. These gatherings help maintain and share our heritage with the community.",
        img: culture1,
    },
    {
        number: "02",
        title: "Language Promotion",
        description: "Our initiatives focus on promoting the Telugu language by offering language classes, workshops, and resources. We aim to ensure that the language is preserved and passed on to future generations.",
        img: culture2,
    },
    {
        number: "03",
        title: "Educational Support",
        description: "We provide educational support through various programs, including scholarships, tutoring, and educational events. Our goal is to enhance the learning experience and opportunities for our community members.",
        img: culture3,
    },
    {
        number: "04",
        title: "Community Assistance",
        description: "We engage in community support activities such as charity fundraisers, helping local and international causes. These events promote a spirit of giving and support within our community.",
        img: culture4,
    }
];

export default OurCausesData;
