import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MenuItems from "../../Data/MenuItems";
const NavMenu = () => {
  return (
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll m-auto">
      {MenuItems.map((menuItem, index) => (
        <li className="nav-item" key={index}>
          <NavLink
            className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
            to={menuItem.link}
            end
          >
            {menuItem.title}{" "}
            {menuItem.subMenu && <i className="fas fa-chevron-down"></i>}
          </NavLink>
          {menuItem.subMenu && (
            <ul className="list-unstyled sub-menu">
              {menuItem.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex} className="submenu-item">
                  <Link to={subMenuItem.link}>{subMenuItem.title}</Link>
                  {subMenuItem.petaMenu && (
                    <>
                      <svg className="peta-menu-icon" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <g fill="none" fillRule="evenodd">
                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path fill="currentColor" d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z" />
                        </g>
                      </svg>
                      <ul className="list-unstyled peta-menu">
                        {subMenuItem.petaMenu.map((petaMenuItem, petaIndex) => (
                          <li key={petaIndex}>
                            <Link to={petaMenuItem.link}>{petaMenuItem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
export default NavMenu;
