import React from 'react';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./contact-us.scss";
const ContactForm = () => {
  return (
    <>
      <Breadcrumb />
      <div className="contact-section-wrapper mb-50">
        <div className="contact-area">
          <div className="container">
            <div className="row align-items-top mb-50">
              <div className="col-lg-5 contact-images-wrapper  position-relative">
                <div className="contact-detail">
                  <div className="title-section">
                    <div className="sub-title">
                      <span>For Your Service</span>
                    </div>
                    <div className="main-title">
                      <h2>Contact Us</h2>
                    </div>
                  </div>
                  <div className="contact-details">
                    <div className="desc">Welcome to the Chicago Telugu Association. We are committed to serving and supporting our community. Whether you need assistance with our programs, have questions about our services, or wish to get involved, we are here to help.
                    </div>
                    <div className="location-detail">
                      <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024"><path fill="currentColor" d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416M512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544" /><path fill="currentColor" d="M512 512a96 96 0 1 0 0-192a96 96 0 0 0 0 192m0 64a160 160 0 1 1 0-320a160 160 0 0 1 0 320" /></svg>
                      </div>
                      <span>Mailing Address: 152 Huron Drive Bloomingdale, IL 60108</span>
                    </div>
                    <div className="location-detail">
                      <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"><path fill="currentColor" d="m6.987 2.066l-.717.216a3.5 3.5 0 0 0-2.454 2.854c-.297 2.068.367 4.486 1.968 7.259c1.597 2.766 3.355 4.548 5.29 5.328a3.5 3.5 0 0 0 3.715-.705l.542-.514a2 2 0 0 0 .247-2.623l-1.356-1.88a1.5 1.5 0 0 0-1.655-.556l-2.051.627l-.053.01c-.226.033-.748-.456-1.398-1.582c-.68-1.178-.82-1.867-.633-2.045l1.043-.973a2.5 2.5 0 0 0 .575-2.85l-.662-1.471a2 2 0 0 0-2.4-1.095m1.49 1.505l.66 1.471a1.5 1.5 0 0 1-.344 1.71l-1.046.974C7.078 8.36 7.3 9.442 8.2 11c.846 1.466 1.618 2.19 2.448 2.064l.124-.026l2.088-.637a.5.5 0 0 1 .552.185l1.356 1.88a1 1 0 0 1-.123 1.312l-.543.514a2.5 2.5 0 0 1-2.653.503c-1.698-.684-3.303-2.311-4.798-4.9C5.152 9.3 4.545 7.093 4.806 5.278a2.5 2.5 0 0 1 1.753-2.039l.717-.216a1 1 0 0 1 1.2.548" /></svg>
                      </div>
                      <span>1-630-409-6800</span>
                    </div>
                    <div className="location-detail">
                      <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32"><path fill="currentColor" d="M28 6H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-2.2 2L16 14.78L6.2 8ZM4 24V8.91l11.43 7.91a1 1 0 0 0 1.14 0L28 8.91V24Z" /></svg>
                      </div>
                      <a href="mailto:info@chicagoteluguassociation.org">info@chicagoteluguassociation.org</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 contact-section-wrapper contact-form">
                <form name="contact-form">
                  <div className="row contact-form-group">
                    <div className="col-lg-12">
                      <label className="required">Name</label>
                      <div className="form-group">
                        <input type="text" className="form-control" name="name" placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label className="required">Email Address</label>
                      <div className="form-group">
                        <input type="email" className="form-control" name="email" placeholder="Your Email" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label className="required">Phone Number</label>
                      <div className="form-group">
                        <input type="number" className="form-control" name="monumber" placeholder="Phone number" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label className="required">Your Message</label>
                      <div className="form-group"><textarea className="form-control contact-message" name="message" id="message"
                        cols="30" rows="5" placeholder="Your Message"></textarea></div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <button type="submit" value="Submit" id="submit" className="default-theme-btn">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.2537161642676!2d-88.05509722175897!3d41.95188514077123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fac4d8198a887%3A0x9142e8f4032fefe9!2s152%20Huron%20Dr%2C%20Bloomingdale%2C%20IL%2060108!5e0!3m2!1sen!2sus!4v1722422622923!5m2!1sen!2sus" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Google Maps location for 152 Huron Dr, Bloomingdale, IL 60108"></iframe>
        </div>
      </div>
    </>
  );
};
export default ContactForm;