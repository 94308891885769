import React from 'react';
import OurteamData from '../Data/OurteamData';
import "./our-team.scss";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
const OurTeam = () => {
    return (
        <>
            <Breadcrumb />
            <div className="our-team mb-50">
                <div className="container">
                    <div className="title-section">
                        <div className="sub-title">
                            <span>Meet our dedicated team</span>
                        </div>
                        <div className="main-title">
                            <h2>Our Team</h2>
                        </div>
                    </div>
                    <div className="team-members">
                        {OurteamData.map((member, index) => (
                            <div className="team-member" key={index}>
                                <img src={member.img} alt={member.name} className="team-photo img-fluid" />
                                <h3 className="team-name">{member.name}</h3>
                                <p className="team-role">{member.role}</p>
                                <p className="team-description">{member.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default OurTeam;
