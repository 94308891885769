import React from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from "../Arrows";
import TestimonialInfoData from "../Data/Testimonial";
import "./testimonial.scss";

function Testimonial() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        autoplay:true,
        autoplaySpeed: 1800,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: { slidesToShow: 2, }
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, }
            }
        ]
    };

  return (
    <div className="testimonial-section-wrapper mb-50 wow fadeInUp">
      <div className="testimonial-section">
        <div className="container">
          <div className="title-section">
            <div className="sub-title">
              <span>Happy community</span>
            </div>
            <div className="main-title">
              <h2>What Our community Members Say</h2>
            </div>
          </div>
          <div className="testimoanial-box-item">
            <div className="testimoanial-slider">
              <Slider {...settings}>
                {TestimonialInfoData.map((data, index) => (
                  <div key={index} className="testimoanial-main-item">
                    <div className="testimoanial-item">
                      <p className="testimoanial-desc">{data.desc}</p>
                      <div className="testimoanial-box">
                        <div className="testimoanial-content">
                          <div className="auther-content">
                            <h4 className="testimoanial-title">{data.title}</h4>
                            <h6 className="testimoanial-designation">{data.designation}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
