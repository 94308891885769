import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import GalleryData from '../Data/GalleryData';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import './gallery.scss';

function Gallery() {
  const [filter, setFilter] = useState('all');
  const [projects, setProjects] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    const filtered = GalleryData.filter(p => p.category.includes(filter));
    setProjects(filtered); // Set filtered projects
  }, [filter]);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
    document.getElementById('main-content').setAttribute('aria-hidden', 'true');
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    document.getElementById('main-content').removeAttribute('aria-hidden');
  };

  return (
    <>
      <Breadcrumb />
      <div id="main-content">
        <div className="gallery-main-wrapper mb-50">
          <div className="container">
            <div className="title-section">
              <div className="sub-title">
                <span>events and achievements</span>
              </div>
              <div className="main-title" data-aos="fade-up">
                <h2>Gallery Showcase</h2>
              </div>
            </div>
            <div className="gallery-filter">
              <button
                className={filter === 'all' ? 'active' : ''}
                onClick={() => setFilter('all')}
              >
                All
              </button>
              <button
                className={filter === '2019' ? 'active' : ''}
                onClick={() => setFilter('2019')}
              >
                2019
              </button>
              <button
                className={filter === '2024' ? 'active' : ''}
                onClick={() => setFilter('2024')}
              >
                2024
              </button>
            </div>
            <div className="gallery-items-wrap">
              <div className="row">
                {projects.length === 0 ? (
                  <div className="no-data">
                    <p>Data not found</p>
                  </div>
                ) : (
                  projects.map((item, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-12">
                      <div className="gallery-item">
                        <div className="image">
                        <LazyLoad offset={100}>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="img-fluid"
                            />
                          </LazyLoad>
                          <div className="zoom-icon" onClick={() => openLightbox(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314M11 8v6m-3-3h6"/>
                            </svg>
                          </div>
                        </div>
                        <div className="gallery-desc">
                          <div className="date">{item.date}</div>
                          <h3 className="gallery-title">{item.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={projects[lightboxIndex].fullSize}
          nextSrc={projects[(lightboxIndex + 1) % projects.length].fullSize}
          prevSrc={projects[(lightboxIndex + projects.length - 1) % projects.length].fullSize}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setLightboxIndex((lightboxIndex + projects.length - 1) % projects.length)
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % projects.length)
          }
        />
      )}
    </>
  );
}

export default Gallery;
