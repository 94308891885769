import history1 from "../../assets/images/history-1.jpg";
import history2 from "../../assets/images/history-2.jpg";
import history3 from "../../assets/images/history-3.jpg";
import history4 from "../../assets/images/history-4.jpg";
import history5 from "../../assets/images/history-5.jpg";

const HistoryData = [
    {
        title: "Founding of Chicago Telugu Association",
        date: "March 15, 2020",
        description: "The Chicago Telugu Association was officially founded with the aim of promoting Telugu culture and providing a platform for the Telugu-speaking community in Chicago to connect and celebrate their heritage.",
        img: history1,
    },
    {
        title: "First Cultural Festival",
        date: "August 22, 2020",
        description: "The association organized its first cultural festival featuring traditional Telugu dance, music, and food. The event was a great success and brought together community members from all over Chicago.",
        img: history2,
    },
    {
        title: "Charity Fundraiser",
        date: "December 10, 2021",
        description: "A charity fundraiser was held to support local and international causes. The event featured auctions, performances, and speeches highlighting the importance of community support and giving back.",
        img: history3,
    },
    {
        title: "Annual Picnic",
        date: "June 5, 2022",
        description: "The annual picnic provided a day of fun and relaxation for families and friends, featuring games, a potluck lunch, and a chance to enjoy the outdoors while building stronger community bonds.",
        img: history4,
    },
    {
        title: "Diwali Celebration",
        date: "October 24, 2022",
        description: "The association hosted a grand Diwali celebration with traditional rituals, cultural performances, and a festive dinner. The event was well-attended and celebrated the festival of lights with great enthusiasm.",
        img: history5,
    }
];

export default HistoryData;
